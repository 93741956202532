import 'bootstrap/dist/css/bootstrap.min.css';

const HomePage = () => {
    return (
       <div className="main-container">

       </div>
    );
};

export default HomePage;